export default class Quantity {
    constructor(element) {
        this.element = document.querySelector(element);
        if (!this.element) return;
        this.btns = document.querySelectorAll(".quantity__btn");
        this.input = document.querySelector(".quantity__input");
        this.form = document.querySelector(".update-cart");
        this.amount = this.input.value;
        this.maximumAmount = this.input.dataset.maximum;
        this.init();
        this.bindEvents();
    }
    init() {
        this.input.value = this.amount;
    }
    bindEvents() {
        this.btns.forEach(btn => {
            btn.addEventListener("click", e => this.changeAmount(e));
        });
    }

    changeAmount(e) {
        e.preventDefault();
        let currentTarget = e.currentTarget;
        let input = currentTarget.parentElement.querySelector("input");
        if (e.currentTarget.dataset.amount == "remove") {
            if (input.value == 1) {
                input.value = 1;
                return;
            }
            input.value--;
        } else {
            // if (this.amount + 1 > this.maximumAmount) return;
            input.value++;
        }

        //TODO:refactor
        currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.submit();
    }
}
