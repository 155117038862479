import Glide from "@glidejs/glide";
import Splide from "@splidejs/splide";

export default class Carousels {
    constructor() {
        this.popular = document.querySelector(".popular__carousel");
        this.detail = document.querySelector(".detail__image-selector-mobile");
        this.detail_horizontal_carousel = document.querySelector(
            "#detail__horizontal-carousel"
        );
        this.initialize();
    }
    initialize() {
        if (this.popular) {
            new Glide(".popular__carousel", {
                type: "carousel",
                perView: 4,
                autoplay: 4000,
                hoverpause: true,
                breakpoints: {
                    991: {
                        perView: 3
                    },
                    576: {
                        perView: 2
                    }
                }
            }).mount();
        }

        if (this.detail) {
            if (this.detail_horizontal_carousel.dataset.imageCount > 3) {
                this.detail.classList.remove(
                    "detail__image-selector-mobile--non-carousel"
                );
                const detail_gallery = new Splide(".detail__image-selector", {
                    direction: "ttb",
                    height: "299px",
                    type: "slide",
                    perPage: 3,
                    pagination: false
                }).mount();

                new Splide(".detail__image-selector-mobile", {
                    type: "slide",
                    height: "9rem",
                    autoWidth: true,
                    gap: ".3rem",
                    perPage: 3,
                    pagination: false,
                    arrows: "false",
                    autoplay: "true"
                }).mount();
            }
        }

        new Glide(".manufacturers__carousel", {
            type: "carousel",
            perView: 4,
            autoplay: 4000,
            hoverpause: true,
            breakpoints: {
                991: {
                    perView: 3
                },
                576: {
                    perView: 2
                }
            }
        }).mount();
    }
}
