export default class Detail {
    constructor(element) {
        this.element = document.querySelector(element);
        if (!this.element) return;
        this.images = document.querySelectorAll(".splide__slide img");
        this.imageSelector = document.querySelector(".detail__image-selector");
        this.imageMobileSelector = document.querySelector(
            ".detail__image-selector-mobile"
        );
        this.selectedImage = document.querySelector(
            ".detail__image-selected img"
        );
        this.arrows = document.querySelectorAll(
            ".detail__image-selector-arrow"
        );
        this.track = document.querySelector(".detail__image-track");
        this.input = document.querySelector(".detail__amount-input");
        this.buttons = document.querySelectorAll(".detail__amount-btn");
        this.sideBarImages;
        this.selectedImageOldUrl;
        this.index = 2;
        this.amount = 1;
        this.maximumAmount = this.input.dataset.maximum;
        this.init();
        this.bindEvents();
    }
    init() {
        this.selectedImageOldUrl = this.selectedImage.src;
        this.input.value = this.amount;
    }
    bindEvents() {
        this.images.forEach(image => {
            image.addEventListener("mouseenter", e => this.selectImage(e));
        });
        this.imageMobileSelector.addEventListener("mouseleave", e =>
            this.returnOldImage(e)
        );
        this.imageSelector.addEventListener("mouseleave", e =>
            this.returnOldImage(e)
        );
        this.buttons.forEach(btn => {
            btn.addEventListener("click", e => this.changeAmount(e));
        });
    }

    changeAmount(e) {
        e.preventDefault();
        if (e.currentTarget.dataset.amount == "remove") {
            if (this.amount == 1) {
                this.amount = 1;
                return;
            }
            this.amount--;
        } else {
            if (this.amount + 1 > this.maximumAmount) return;
            this.amount++;
        }
        this.input.value = this.amount;
    }
    selectImage(e) {
        let imageLink = e.currentTarget.alt;
        this.selectedImage.src = imageLink;
    }
    returnOldImage() {
        this.selectedImage.src = this.selectedImageOldUrl;
    }
}
