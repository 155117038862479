import Carousels from "./components/carousels";
import Categories from "./components/categories";
import ChildList from "./components/childList";
import Detail from "./components/detail";
import Quantity from "./components/quantity";
require("./bootstrap");
require("@staaky/fresco");
require("jquery-match-height");
require("vanilla-cookieconsent");

import swal from "sweetalert";

window.swal = swal;

window.Detail = new Detail(".detail");
window.Carousels = new Carousels();
window.Categories = new Categories(".mobile");
window.Quantity = new Quantity(".quantity");
//window.ChildList = new ChildList('.child-list');
$(function () {
    setTimeout(() => {
        $(".item").matchHeight();
    }, 300);
});
