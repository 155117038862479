export default class Categories {
    constructor(element) {
        this.element = document.querySelector(element);
        if (!this.element) return;
        this.categories = document.querySelectorAll(".mobile__categories-item");
        this.init();
        this.bindEvents();
    }
    init() {}
    bindEvents() {
        this.categories.forEach(item => {
            item.addEventListener("click", e => this.toggleList(e));
        });
    }
    toggleList(e) {
        let item = e.currentTarget.children[1];
        let image = e.currentTarget.children[0].children[0];
        if (image.tagName == "IMG") {
            image.classList.toggle("mobile__categories-arrow--active");
            item.classList.toggle("mobile__categories-sublist--active");
        }
    }
}
